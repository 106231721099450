import React from "react"
import { animated } from "react-spring"
import styled from "styled-components"
import Img from "gatsby-image"
import { breakpoints } from "../../utils/styles"

const Wrapper = styled(animated.div)`
  grid-area: gallery;
  background: #fff;
  width: 100%;

  @media (min-width: ${breakpoints.lg}) {
    overflow-y: scroll;
    ::-webkit-scrollbar {
      width: 0;
      background: transparent; /* make scrollbar transparent */
    }
  }
`

const ImgContainer = styled.div``

const WorkGallery = ({ style, gallery }) => {
  return (
    <Wrapper style={{ ...style }}>
      {gallery.map((i, index) => {
        const { gallery_image } = i
        return (
          <ImgContainer key={index}>
            <Img fluid={gallery_image.localFile.childImageSharp.fluid} />
          </ImgContainer>
        )
      })}
    </Wrapper>
  )
}

export default WorkGallery
