import React from "react"
import styled from "styled-components"

const RootButton = styled.a`
  cursor: pointer;
  width: fit-content;
  text-decoration: none;
  color: ${props => props.color && props.color};
  border: 3px solid ${props => props.color && props.color};
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  font-family: var(--super-regular-font);

  background: linear-gradient(to right, #000, #000) no-repeat;
  background-size: 0 100%;
  transition: color 500ms, border-color 500ms, background-size 500ms 0s;

  :visited {
    color: ${props => props.color && props.color};
  }

  :hover {
    background-size: 100% 100%;
    color: #fff;
    border-color: black;
  }
`

const ButtonLink = ({ children, onClick, className, href, color }) => {
  return (
    <RootButton
      color={color}
      href={href}
      target="_blank"
      onClick={onClick}
      className={className}
    >
      {children}
    </RootButton>
  )
}

export default ButtonLink
