import React from "react"
import styled from "styled-components"
import { breakpoints } from "../../utils/styles"
import ButtonLink from "../Button/ButtonLink"

const TextContainer = styled.div`
  background: ${props => (props.containerWhite ? "#fff" : "#000")};
  color: ${props => (props.containerWhite ? "#000" : "#fff")};

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4rem 2rem;
  order: 2;

  @media (min-width: ${breakpoints.lg}) {
    padding: ${props => (props.isCommunity ? "5rem 2rem" : "3rem 2rem")};
  }

  @media (min-width: ${breakpoints.xl}) {
    padding: ${props => (props.isCommunity ? "5rem 2rem" : "2rem")};
    order: ${props => (props.left && props.left ? "1" : "2")};
  }

  @media (min-width: ${breakpoints.mdxl}) {
    padding: ${props => (props.isCommunity ? "5rem 2rem" : "3rem")};
  }

  @media (min-width: ${breakpoints.xxl}) {
    padding: ${props => (props.isCommunity ? "5rem 2rem" : "3rem 5rem")};
  }
`

const OrgTitle = styled.h3`
  text-transform: uppercase;
  font-size: 2rem;
  margin: 0;
  line-height: 1.5;

  @media (min-width: ${breakpoints.xl}) {
    font-size: ${props => (props.isCommunity ? "2.25rem" : "1.75rem")};
  }

  @media (min-width: ${breakpoints.mdxl}) {
    font-size: ${props => (props.isCommunity ? "2.25rem" : "2rem")};
  }
`
const OrgPosition = styled.h4`
  font-size: ${props => (props.isCommunity ? "1.25rem" : "1.125rem")};
  font-family: var(--body-font);
  margin: 0;

  @media (min-width: ${breakpoints.md}) {
  }

  @media (min-width: ${breakpoints.lg}) {
  }

  @media (min-width: ${breakpoints.xl}) {
  }
`
const OrgDescription = styled.p`
  font-size: 1.25rem;
  line-height: ${props => (props.isCommunity ? "1.75" : "1.5")};

  @media (min-width: ${breakpoints.md}) {
  }

  @media (min-width: ${breakpoints.xl}) {
    font-size: ${props => (props.isCommunity ? "1.125rem" : "1rem")};
  }

  @media (min-width: ${breakpoints.mdxl}) {
  }

  @media (min-width: ${breakpoints.xxl}) {
    font-size: 1.125rem;
  }
`

const ListContainer = styled.div`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (min-width: ${breakpoints.xl}) {
    margin-top: 0.5rem;
  }
`

const List = styled.div``

const ListItem = styled.div`
  display: flex;
  flex-direction: column;

  ul {
    list-style: none;
    padding: 0;
    margin: 0.75rem 0 0;
  }

  li {
    font-size: 1rem;
    line-height: ${props => (props.isCommunity ? 2 : 1.75)};
  }

  @media (min-width: ${breakpoints.xl}) {
    li {
      font-size: ${props => (props.isCommunity ? "1.125rem" : ".75rem")};
    }
  }

  @media (min-width: ${breakpoints.mdxl}) {
    li {
      font-size: ${props => (props.isCommunity ? "1.125rem" : "1rem")};
    }
  }
`

const ListTitle = styled.h5`
  margin: 0;
  font-family: var(--subheader-font);
  font-size: 1rem;

  @media (min-width: ${breakpoints.xl}) {
    font-size: ${props => (props.isCommunity ? "1.125rem" : ".875rem")};
  }
`

const StyledButtonLink = styled(ButtonLink)`
  margin-top: 1rem;
  padding: 0.75rem 1rem;
  font-size: 1rem;
`

const OrganizationText = ({
  className,
  left,
  title,
  position,
  description,
  highlights,
  goals,
  containerWhite,
  link,
  isCommunity,
}) => {
  return (
    <TextContainer
      isCommunity={isCommunity}
      left={left}
      className={className}
      containerWhite={containerWhite}
    >
      <OrgTitle isCommunity={isCommunity}>{title.text}</OrgTitle>
      <OrgPosition isCommunity={isCommunity}>{position.text}</OrgPosition>
      <OrgDescription isCommunity={isCommunity}>
        {description.text}
      </OrgDescription>
      <ListContainer>
        <ListItem isCommunity={isCommunity}>
          <ListTitle isCommunity={isCommunity}>Highlights</ListTitle>
          <List
            isCommunity={isCommunity}
            dangerouslySetInnerHTML={{ __html: highlights.html }}
          />
        </ListItem>
        <ListItem isCommunity={isCommunity}>
          <ListTitle isCommunity={isCommunity}>Goals</ListTitle>
          <List dangerouslySetInnerHTML={{ __html: goals.html }} />
        </ListItem>
      </ListContainer>
      {link && (
        <StyledButtonLink color="#000" href={link.url}>
          Visit Website
        </StyledButtonLink>
      )}
    </TextContainer>
  )
}

export default OrganizationText
