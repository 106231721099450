import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { backgroundGatsbyImage } from "../../utils/styles"
import Button from "../Button/Button"

const Container = styled.div`
  grid-area: ${props => props.gridArea};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 0;
  overflow: hidden;
  min-height: 350px;
  height: 40vh;
  max-height: 500px;

  .gatsby-image-wrapper,
  .overlay {
    transition: all 300ms;
  }

  :hover {
    .gatsby-image-wrapper {
      transform: scale(1.05);
    }

    .overlay {
      background: rgba(0, 0, 0, 0.3);
    }
  }
`

const Overlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1;
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 2.5rem 3rem;
  z-index: 3;
`

const Title = styled.h2`
  margin: 0;
  font-family: var(--header-font);
  text-transform: uppercase;
  color: #fff;
  font-size: 4rem;
`

const Subtitle = styled.p`
  color: #fff;
  margin: 0;
  font-size: 1rem;
`

const LogoContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 80px;
`

const StyledButton = styled(Button)`
  margin-top: 1.5rem;
  z-index: 1;
  border-width: 2px;
  font-family: var(--regular-font);
  letter-spacing: 2px;
  font-size: 0.875rem;
  padding: 0.75rem 1.5rem;
`

const CommunityWork = ({ gridArea, toggleOrg, work }) => {
  const { org_image, org_logo, org_title, org_position } = work.primary

  return (
    <Container gridArea={gridArea}>
      <Overlay className="overlay" />
      <Img
        fluid={org_image.localFile.childImageSharp.fluid}
        style={backgroundGatsbyImage}
      />
      <ContentContainer>
        <Title>{org_title.text}</Title>
        <Subtitle>{org_position.text}</Subtitle>
        <StyledButton onClick={() => toggleOrg(work)} color="#fff">
          learn more
        </StyledButton>
        <LogoContainer>
          <img src={org_logo.url} alt={org_logo.alt} />
        </LogoContainer>
      </ContentContainer>
    </Container>
  )
}

export default CommunityWork
