import React from "react"
import styled from "styled-components"
import { animated } from "react-spring"
import OrganizationText from "../OrganizationText/OrganizationText"
import { MdClose } from "react-icons/md"

const Wrapper = styled(animated.div)`
  width: 100%;
  grid-area: description;
  background: #fff;
  position: relative;
`

const CloseButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  top: 25px;
  right: 30px;

  svg {
    width: 25px;
    height: 25px;
  }
`

const WorkDescription = ({
  style,
  close,
  work: {
    org_title,
    org_position,
    org_description,
    highlights,
    goals,
    org_link,
  },
}) => {
  return (
    <Wrapper style={{ ...style }}>
      <CloseButton onClick={close}>
        <MdClose />
      </CloseButton>
      <OrganizationText
        title={org_title}
        position={org_position}
        description={org_description}
        highlights={highlights}
        goals={goals}
        containerWhite
        link={org_link}
        isCommunity
      />
    </Wrapper>
  )
}

export default WorkDescription
