import React from "react"
import { useTransition, animated } from "react-spring"
import styled from "styled-components"
import WorkDescription from "./WorkDescription"
import WorkGallery from "./WorkGallery"
import { breakpoints } from "../../utils/styles"

const Root = styled(animated.div)`
  z-index: 100;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  //z-index: 100;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0;
    background: transparent; /* make scrollbar transparent */
  }
  grid-template-areas: "description" "gallery";
  display: grid;

  @media (min-width: ${breakpoints.lg}) {
    grid-template-areas: "gallery description";
    grid-template-columns: 55% 45%;
  }
`

const SlideInContent = ({ work, gallery, isOrgOpen, close }) => {
  const rootTransition = useTransition(isOrgOpen, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  const leftTransition = useTransition(isOrgOpen, null, {
    from: { transform: "translate3d(-100%,0,0)" },
    enter: { transform: "translate3d(0,0,0)" },
    leave: { transform: "translate3d(-100%,0,0)" },
  })

  const rightTransition = useTransition(isOrgOpen, null, {
    from: { transform: "translate3d(100%,0,0)" },
    enter: { transform: "translate3d(0,0,0)" },
    leave: { transform: "translate3d(100%,0,0)" },
  })

  return (
    <>
      {rootTransition.map(
        ({ item, key, props }) =>
          item && (
            <Root style={props} key={key}>
              {leftTransition.map(
                ({ item, key, props }) =>
                  item && (
                    <WorkGallery key={key} style={props} gallery={gallery} />
                  )
              )}
              {rightTransition.map(
                ({ item, key, props }) =>
                  item && (
                    <WorkDescription
                      style={props}
                      key={key}
                      work={work}
                      close={close}
                    />
                  )
              )}
            </Root>
          )
      )}
    </>
  )
}

export default SlideInContent
