import React from "react"
import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import CommunityContent from "../components/CommunityContent/CommunityContent"
import { graphql } from "gatsby"

const CommunityPage = props => {
  const {
    data: {
      prismicCommunity: { data },
    },
    location,
  } = props
  return (
    <Layout path={location.pathname}>
      <SEO title="Community" />
      <CommunityContent community={data} />
    </Layout>
  )
}

export default CommunityPage

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(quality: 100) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
`

export const communityQuery = graphql`
  query communityQuery {
    prismicCommunity {
      data {
        title {
          text
        }
        subtitle {
          text
        }
        description {
          text
        }
        body {
          primary {
            org_title {
              text
            }
            org_position {
              text
            }

            org_image {
              localFile {
                ...fluidImage
              }
            }
            org_logo {
              url
              alt
            }
            org_description {
              text
            }
            org_link {
              url
            }
            goals {
              html
            }
            highlights {
              html
            }
          }
          items {
            gallery_image {
              localFile {
                ...fluidImage
              }
            }
          }
        }
      }
    }
  }
`
