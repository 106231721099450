import React, { useState } from "react"
import styled from "styled-components"
import { breakpoints } from "../../utils/styles"
import CommunityWork from "./CommunityWork"
import SlideInContent from "./SlideInContent"

const GridContainer = styled.div`
  display: grid;
  grid-template-areas:
    "community"
    "top"
    "bottom";
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  @media (min-width: ${breakpoints.lg}) {
    grid-template-columns: 4fr 5fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      "community top"
      "community bottom";
  }
`

const CommunityPanel = styled.div`
  margin-top: 5rem;
  grid-area: community;
  display: flex;
  flex-direction: column;
  justify-content: center;
  //align-items: center;
  padding: 3rem 2rem;

  @media (min-width: ${breakpoints.lg}) {
    margin-top: 0;
  }
`

const TextContainer = styled.div`
  text-align: left;
`

const Title = styled.h1`
  margin: 0;
  font-family: var(--header-font);
  text-transform: uppercase;
  font-size: 4rem;
`

const Subtitle = styled.p`
  margin: 0;
  font-family: var(--subheader-font);
  font-size: 1.875rem;
`

const Description = styled.p`
  color: #999999;
  margin: 1.25rem 0 0;
  font-size: 1.625rem;
`

const CommunityContent = ({ community }) => {
  const [isOrgOpen, setOrgOpen] = useState(false)
  const [viewedOrg, setViewedOrg] = useState(community.body[0])

  const toggleOrgOpen = org => {
    setViewedOrg(org)
    setOrgOpen(!isOrgOpen)
  }

  return (
    <GridContainer>
      <CommunityPanel>
        <TextContainer>
          <Title>{community.title.text}</Title>
          <Subtitle>{community.subtitle.text}</Subtitle>
          <Description>{community.description.text}</Description>
        </TextContainer>
      </CommunityPanel>
      <CommunityWork
        gridArea="top"
        work={community.body[0]}
        toggleOrg={toggleOrgOpen}
      />
      <CommunityWork
        gridArea="bottom"
        work={community.body[1]}
        toggleOrg={toggleOrgOpen}
      />
      <SlideInContent
        work={viewedOrg.primary}
        gallery={viewedOrg.items}
        isOrgOpen={isOrgOpen}
        close={() => setOrgOpen(false)}
      />
    </GridContainer>
  )
}

export default CommunityContent
